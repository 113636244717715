.container {
	@apply flex w-full h-[3.25rem] px-2 py-4 justify-center items-center self-stretch rounded-lg border border-primary-600 border-solid outline-none;

	&.primary {
		@apply bg-primary-600 text-neutral-0;

		&:disabled {
			@apply cursor-not-allowed opacity-40;

			&:hover {
				@apply bg-primary-600 text-neutral-0 transition;
			}
		}

		&:hover {
			@apply bg-neutral-0 text-primary-600 transition;
		}
	}

	&.outline {
		@apply bg-neutral-0 text-primary-600;

		&:disabled {
			@apply cursor-not-allowed opacity-40;

			&:hover {
				@apply bg-neutral-0 text-primary-600 transition;
			}
		}

		&:hover {
			@apply bg-primary-600 text-neutral-0 transition;
		}
	}

	&.text {
		@apply bg-neutral-0 text-primary-600 border-none;

		&:disabled {
			@apply cursor-not-allowed opacity-40;

			&:hover {
				@apply bg-neutral-0 text-primary-600 transition;
			}
		}
	}

	&.warningText {
		@apply bg-neutral-0 text-primary-600 border-none;
		color: #F65050;

		&:disabled {
			@apply cursor-not-allowed opacity-40;

			&:hover {
				@apply bg-neutral-0 text-primary-600 transition;
			}
		}
	}

	&.superPerfil {
		background-color: #00f5c9;
		border-color: #00f5c9;
		color: #3a3736;

		&:hover {
			@apply bg-neutral-0 transition;
		}
	}
}
