.container {
  @apply p-12 mt-16;
}

.registerContainer {
  @apply text-base;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin: auto;
  max-width: 720px;

  * {
    @apply text-left;
    font-family: Inter;
  }
  
  .logo{
    margin-bottom: 24px;
  }

  .submit{
    max-width: 720px;
    margin-top: 16px;
  }
  
}

.successContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}

.messageTextarea {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
}