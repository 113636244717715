.container {
	padding: 0 72px;
	@apply w-full py-4;

	.wrapper {
		@apply max-w-[720px] w-full mx-auto my-0;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		@apply px-4;
	}
}
