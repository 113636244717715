.container {
	@apply w-full h-14 border border-solid px-2 py-3 rounded items-center justify-between flex gap-1;
	border-color: #cecbca;

	&.error {
		border-color: #f65050;
		background-color: #fbe9e9;

		label {
			color: #751515;
		}

		input {
			color: #751515;
			background: none;
		}

		&:focus-within {
			border-color: #f65050;
			box-shadow: 0px 0px 0px 4px #fbe9e933;
		}
	}

	&.disabled {
		@apply cursor-not-allowed;
		background-color: #e7e5e4;
		border-color: #e7e5e4;

		label {
			color: #a8a5a4;
		}

		input {
			background-color: transparent;
		}
	}

	&:focus-within {
		border-color: #773faf;
		box-shadow: 0px 0px 0px 4px #dec8f333;
		@apply transition;
	}

	label {
		@apply w-full flex flex-col gap-1;
		letter-spacing: 0.04em;
	}

	input {
		@apply w-full h-5 text-neutral-900;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type="number"] {
			-moz-appearance: textfield;
		}

		&:focus-visible {
			outline: none !important;
		}
	}
}

.inputWrapper {
	display: flex;
	width: 100%;
	flex-direction: column;
}