.container {
	@apply w-screen h-screen flex items-center justify-center bg-neutral-800 bg-opacity-60 fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2;

	.header {
		@apply w-full flex items-center justify-between;
	}

	.content {
		@apply flex flex-col gap-5;
	}

	> div {
		@apply w-[calc(100%-2rem)] h-fit absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 rounded-lg p-4 overflow-auto;
	}
}
