.container {
	@apply w-full h-14 pb-14 bg-neutral-0 sticky top-0 z-10;
	padding: 0 72px;

	.wrapper {
		max-width: 720px;
		margin: 0 auto;
		@apply flex justify-between gap-2 items-center py-4;

		> div {
			@apply w-6 h-6;
		}
	}
}

.containerWithLogo {
	@apply w-full h-28 pb-12 bg-neutral-0 sticky top-0 z-10;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	padding: 0 72px;
	.wrapperWithLogo {
		align-self: center;
		justify-content: center;
		align-items: center;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		@apply px-4;
	}
}
