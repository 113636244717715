.container {
	@apply w-full min-h-[600px] mx-auto flex;

	.image {
		@apply w-full bg-no-repeat bg-right bg-contain relative;
		background-image: url(../../assets/images/bg-illustration.svg);

		&::before {
			@apply absolute top-0 w-full h-full;
			z-index: -1;
			background: #f2ecf8;
			content: "";
		}
	}

	.wrapper {
		@apply w-full h-screen overflow-auto flex flex-col justify-center items-center px-20;

		.contentForm {
			@apply max-w-[720px] w-full;
		}
	}
}

@media only screen and (max-width: 1920px) {
	.container {
		.image {
			@apply bg-cover;
		}
	}
}

@media only screen and (max-width: 1440px) {
	.container {
		.image {
			@apply bg-left;
		}

		.wrapper {
			@apply px-16;
		}
	}
}

@media only screen and (max-width: 1100px) {
	.container {
		.image {
			@apply max-w-[200px] bg-center;
		}
	}
}

@media only screen and (max-width: 900px) {
	.container {
		@apply flex-col;

		.image {
			@apply h-32 max-w-full w-screen flex-none;
			background-image: url(../../assets/images/img-ilustration-mobile.jpg);

			&:before {
				@apply hidden;
			}
		}

		.wrapper {
			@apply max-w-full w-full h-auto px-10 my-10;
		}
	}
}

@media only screen and (max-width: 600px) {
	.container {
		.wrapper {
			@apply px-4;
		}
	}
}
