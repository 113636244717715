.policyContainer {
  @apply text-base;
  background: #fff;
  padding: 80px 100px;

  * {
    @apply text-justify;
  }
  p{
    margin-top: 10px;
  }
  li{
    list-style-type: disc;
    margin-left: 40px;
  }

  h1, h2, h3, h4{
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h1{
    @apply text-3xl;
  }
  h2{
    @apply text-2xl;
  }
  h3{
    @apply text-xl;
  }
  h4{
    @apply text-lg;
  }

  table, th, td {
    border: 1px solid;
    padding: 4px;
  }
  hr{
    @apply my-5;
  }
  
}


@media only screen and (max-width: 900px) {
	.policyContainer {
    padding: 80px 60px;
	}
}

@media only screen and (max-width: 600px) {
	.policyContainer {
    padding: 80px 40px;
	}
}
