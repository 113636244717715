.acceptedBudget {
	color: #11A20F;
}

.franchiseeName {
	@apply font-semibold text-sm;
}

.locationInformation {
	@apply text-sm;
	color: #3A3736;

	.infoLabel {
		@apply font-semibold;
	}
}
