.container-checkbox {
	width: 100%;
	position: relative;

	&.error {
		input[type="checkbox"] + label > span {
			@apply text-semantic-red_500;
		}
	}

	input[type="checkbox"] {
		width: 0;
		height: 0;
		opacity: 0;
		position: absolute;
		display: flex;
		align-items: center;

		& + label {
			@apply relative cursor-pointer p-0 flex items-start;

			> span {
				@apply pl-8 text-neutral-900;
			}

			a,
			button {
				@apply font-semibold;

				&:hover {
					@apply text-primary-600;
				}
			}
		}

		& + label:before {
			content: "";
			@apply mr-2;
			display: block;
			@apply w-6 h-6 border border-solid border-neutral-200 bg-neutral-0 absolute rounded;
		}

		&:hover + label:before {
			@apply bg-neutral-50;
		}

		&:disabled + label {
			@apply text-neutral-400 cursor-auto;
		}

		&:disabled + label:before {
			@apply bg-neutral-200 border-neutral-200 cursor-not-allowed shadow-none;
		}

		&:checked + label:before {
			@apply bg-primary-500 border-neutral-0;
		}

		&:checked + label:after {
			content: "";
			position: absolute;
			top: 0.7rem;
			left: 0.4rem;
			width: 2px;
			height: 2px;
			box-shadow: 2px 0 0 #fff, 4px 0 0 #fff, 4px -2px 0 #fff, 4px -4px 0 #fff,
				4px -6px 0 #fff, 4px -8px 0 #fff;
			transform: rotate(45deg);
			@apply bg-neutral-0;
		}

		&:focus + label:before {
			@apply border-primary-600;
		}

		&:active + label:before {
			@apply border-primary-600;
		}
	}
}
