.registerContainer {
  @apply text-base;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;

  * {
    @apply text-left;
    font-family: Inter;
  }
  
  .logo{
    margin-bottom: 24px;
  }

  .card{
    @apply text-base;
    max-width: 720px;
    margin-top: 8px;
  }

  .submit{
    max-width: 720px;
    margin-top: 16px;
  }
  
}

@media only screen and (min-width: 900px) {
  .card{
    width: 50%;
  }
}

@media only screen and (max-width: 900px) {
  .card{
    width: 90%;
  }
}

.successContainer{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 50px;
}