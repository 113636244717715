

.container {
	@apply flex gap-2 items-center;
	padding-right: 16px;
	padding-left: 16px;

	&.userContainer {
		@apply flex-row-reverse;
	}

	.avatar {
		@apply w-12 h-12 rounded-full overflow-hidden;

		img {
			@apply w-full h-full object-cover;
		}
	}

	.userName {
		@apply flex gap-2 items-center;
	}
}
