.container {
	@apply flex flex-col gap-6 items-center text-center mb-10;

	svg {
		@apply max-w-[310px] w-full h-auto;
	}

	p {
		span {
			@apply text-primary-600;
		}
	}
}

.icon {
	padding-top: 68px;
	width: 80px;
}

@media only screen and (max-width: 900px) {
	.container {
		svg {
			max-width: 143px;
		}
	}
}

@media only screen and (max-width: 600px) {
	.container {
		@apply w-full;
	}
}
