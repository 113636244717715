
.loadingWrapper {
	@apply w-full h-32;
	display: flex;
    justify-content: center;
    align-items: center;

    span {
        border-color: #fff;
        border-style: solid;
        border-bottom-color: #773faf;
        border-radius: 50%;
        display: inline-block;
        box-sizing: border-box;
        animation: rotation 1s linear infinite;
        }
    
        @keyframes rotation {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}

.loadingWrapper.small{
    span {
        width: 24px;
        height: 24px;
        border-width: 3px;;
    }
}

.loadingWrapper.regular {
    span {
        width: 48px;
        height: 48px;
        border-width: 5px;
    }
}

.loadingWrapper.large {
    span {
        width: 48px;
        height: 48px;
        border-width: 5px;
    }
}