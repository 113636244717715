

.container {
	@apply flex gap-2 items-center;
	padding-right: 16px;
	padding-left: 16px;
}

.userContainer {
	@apply flex gap-2 items-center;
	padding-right: 16px;
	padding-left: 16px;
	justify-content: flex-end;
}

.userName {
	@apply flex gap-2 items-center;

}

.messageContainer {
	@apply w-full  flex p-4 flex-col items-start gap-2 self-stretch rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-left: 32px;
	margin-right: 32px;
	margin-top: 8px;
	margin-bottom: 24px;
	padding: 16px;
	width: calc(100% - 128px);
}

.userMessageContainer {
	@apply flex p-4 flex-col items-start gap-2 rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-left: auto;
	margin-right: 32px;
	margin-top: 8px;
	margin-bottom: 24px;
	padding: 16px;
	max-width: calc(100% - 128px);
	min-width: min-content;
}

.userMessageContainerReply {
	@apply w-full flex p-4 flex-col items-start gap-4 self-stretch rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-top: 8px;
	margin-bottom: 24px;
	margin-right: 32px;
	margin-left:32px;
	padding: 16px;
	width: calc(100% - 64px);
}

.avatar {
	@apply w-12 h-12 rounded-full overflow-hidden;

	img {
		@apply w-full h-full object-cover;
	}
}

.messageContent {
	white-space: pre-line;

}