.container {
	padding: 0 72px;
	@apply bg-neutral-0 pt-1 pb-3;

	.wrapper {
		@apply max-w-[720px] w-full relative flex flex-col gap-2;
		margin: 0 auto;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		@apply px-4;
	}
}
