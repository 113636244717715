.container {
  @apply p-12;
}

.registerContainer {
  @apply text-base;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  margin: auto;
  max-width: 720px;

  * {
    @apply text-left;
    font-family: Inter;
  }
  
  .logo{
    margin-bottom: 24px;
  }
  .submit{
    max-width: 720px;
    margin-top: 16px;
  }
  
}
