.landingHeader {
  @apply w-full flex flex-row items-center justify-between sticky top-0 bg-neutral-0 p-4 pl-8;
  z-index: 15;

  .headerLogo {
    @apply flex flex-row justify-center items-center w-[5.15rem] relative h-12;
    overflow: hidden;

    > svg {
      @apply h-8;
      position: absolute;
      right: 0;
    }
  }

  button {
    @apply w-fit p-4;
  }
}

.landingPageWrapper {
  @apply flex flex-col w-full h-max text-neutral-700 bg-neutral-0;

  button {
    @apply w-full md:w-fit;
    padding: 1rem;
    z-index: 10;
    
    span {
      font-size: 1rem;
      font-weight: 400;
    }
  }

  .hero {
    @apply w-full flex flex-col justify-center items-center;
    padding: 10rem 2rem;
    background-image: linear-gradient(#ffffffff, #f8e9e266 50%);

    .heroContent {
      @apply flex flex-col items-center justify-center gap-6 text-center;

      .heroText {
        @apply flex flex-col;

        h1 {
          font-family: "DM Serif Display";
          font-size: 2.25rem;
          font-weight: 400;
        }

        h2 {
          font-size: 1.4rem;
          font-weight: 400;
        }
      }

      .heroOptions {
        @apply flex flex-col items-center justify-center gap-4;
        @apply md:flex-row;
      }

    }
  }

  .splitContent, .reverseSplit, .partnerForm {
    @apply w-full relative flex flex-col md:gap-8 items-center justify-center overflow-x-hidden overflow-y-visible;
    @apply md:flex-row md:gap-16 md:min-h-[37.5rem] md:px-8;

    .textContainer {
      @apply py-10 px-4 w-full;
      @apply md:h-full md:w-[32rem] md:py-[2.5rem] md:px-0;
      @apply flex flex-col gap-4 justify-center items-start;
    }
  
    .imageContainer{
      @apply flex flex-col justify-center items-center ;
      @apply md:flex-row md:justify-center md:items-center;
      @apply h-[80%] w-full ;
      @apply md:w-[32rem] md:h-full;

      .desktopImage {
        position: absolute;
        object-fit: cover;
        @apply h-[37.5rem] hidden md:block;
      }
    
      .mobileImage {
        margin-bottom: -8rem;
        @apply w-full md:hidden block;
      }
    }
  }

  .splitContent {
    @apply md:flex-row md:gap-16;
    margin-top: -4rem;
  }

  .reverseSplit {
    @apply md:flex-row-reverse md:gap-16;
  }

  .partnerForm {
    @apply flex flex-col justify-start;
    @apply md:flex-row md:gap-16 md:justify-center bg-neutral-50 overflow-hidden;

    .partnerFormField {
      @apply bg-neutral-0 md:w-[20rem] w-full;
      z-index: 10;
    }

    .imageContainer{
      @apply md:mt-[4rem] mt-[-4rem] border-0;

      .desktopImage {
        @apply md:h-[44rem];
        mask-image: radial-gradient(circle at 0%, #000000 80%, #00000000 92.5%);
      }
    }

    .partnerActionButton {
      @apply gap-4;
      
      svg {
        path {
          fill: white;
        }
      }
      
      &:hover {
        svg {
          path {
            @apply transition;
            fill: #772AC4;
          }
        }
      }
    }
  }

  .iconRow {
    margin-top: -8rem;
    @apply w-full hidden md:flex flex-row gap-16 py-[5rem] px-[7.5rem] flex-wrap items-center justify-center;

    .serviceIcon {
      @apply flex flex-col items-center justify-center gap-4 w-[6rem] uppercase text-neutral-500;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 600;
      letter-spacing: 0.04em;

      > img {
        @apply w-[2.5rem] border-0;
      }
    }

    .fancy {
      font-family: "DM Serif Display";
      font-size: 1.5rem;
      font-weight: 400;
    }
  }

  .textContainer {
    > h1, > h2, > p {
      text-wrap: wrap;
      font-weight: 400;
      @apply w-full;
    }
    
    h1 {
      font-family: "DM Serif Display";
      font-size: 2.25rem;
      line-height: 2.5rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    > button {
      @apply w-fit p-4;
    }
  }

  .appStoreRow{
    @apply w-full relative flex flex-col gap-8 items-center justify-center py-10 md:px-8 px-4;
    @apply md:min-h-[37.5rem] md:flex-row md:gap-16;

    .textContainer, .appStoreCard {
      @apply max-w-[22rem] md:w-[20rem] w-full;
    }
    
    .appStoreCard {
      @apply h-[12rem] md:h-[20rem] rounded-[2rem] p-4 md:p-8;
      background-image: url("/assets/images/landing/desktop/store-bg.png");
      background-position: center;
      background-size: cover;

      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      > img {
        @apply h-[6rem] md:h-[11rem] md:py-8 py-0;
      }

      > button {
        @apply w-fit;
        align-self: unset;
      }
    }
  }

  .smallLogo {
    @apply h-8 flex flex-row justify-start items-center;

    > svg {
      @apply h-full w-min;
    }
  }
}

.landingFooter{
  @apply md:py-[10rem] py-[5rem] w-full flex flex-col gap-4 items-center justify-center bg-neutral-50;

  .footerLogo {
    @apply w-32 flex flex-row justify-start items-center;

    > img {
      @apply h-full w-min;
    }
  }
}