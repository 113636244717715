.container {
	@apply bg-neutral-0 rounded-lg p-4 flex justify-between items-center cursor-pointer border border-solid border-neutral-0;
	box-shadow: 0px 0px 10px 0px #6625001a;

	&.active,
	&:hover {
		@apply border-primary-700 bg-primary-100 outline-none transition;
	}

	> div {
		@apply flex gap-4 items-center mr-2;
	}

	.icon {
		@apply w-6 h-6;

		img {
			@apply w-full block;
		}
	}
}
