.badgeWrapper {
  @apply flex w-fit items-center gap-1 px-2 py-1 mb-2;
  border-radius: 40px;
  font-weight: 500;
}

.badgeOrderRequest {
  background-color: #cecbca;
}

.badgeTimestamp {
  background-color: #FFCEB2;
}

.badgeCategory {
  @apply bg-neutral-0;
  border-width: 1px;
  border-color: #FFCEB2;
  text-transform: uppercase;
}
