.replySection {
	@apply flex flex-col items-start w-full gap-4 text-neutral-500;

	.sectionTitle {
		@apply font-normal text-lg text-neutral-700 text-start;
	}

	.sectionDescription {
		@apply text-base text-neutral-500 text-start;
	}

	.inlineContainer {
		@apply flex flex-row justify-between gap-4 w-full;
	}
}
