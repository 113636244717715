.container {
	padding: 0 72px;
	@apply bg-neutral-0 py-3;

	.wrapper {
		@apply max-w-[720px] w-full relative;
		margin: 0 auto;

		p {
			@apply text-primary-500 font-semibold;

			span {
				@apply text-primary-300;
			}
		}
	}

	.progress {
		@apply w-full h-1 gap-1 flex items-center my-2;

		.step {
			@apply w-full h-1 bg-primary-100;

			&.super {
				@apply relative;
				background-color: #c1fff4;

				.ico {
					@apply w-6 h-6 absolute right-0 top-1/2 -translate-y-1/2 rounded-full flex items-center justify-center;
					background-color: #c1fff4;
					&.active {
						background-color: #00f5c9;
					}
				}

				&.active {
					background-color: #00f5c9;
					max-width: calc(100% - 1.5rem);

					.ico {
						background-color: #00f5c9;
					}
				}
			}

			&.active {
				@apply bg-primary-500;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.container {
		@apply px-4;
	}
}
