.requestListWrapper {
  @apply w-full gap-8 grid;

  .requestListTitle {
    @apply font-normal text-lg;
    color: #3A3736;
  }

  .requestListContent {
    @apply grid gap-4;
  }
}
