.container {
  @apply flex flex-col items-center justify-center py-4;

  img {
    max-width: 500px;
    width: 100%;
  }

  .buttonFooter {
    @apply w-full mt-10 grid gap-2;
  }
}

.BodySuperPerfil {
  @apply h-screen;
  background: url(../../../assets/images/bg-pattern.svg) center bottom repeat-x #D7F9F3;
  background-size: contain;
}
