$sidemenu-transition: transform 0.5s, visibility 0.5s;
$backdrop-transition: opacity 0.5s, visibility 0.5s;

.sideMenuBackdrop {
	@apply w-screen h-screen fixed opacity-0 invisible top-0;

	background-color: transparentize(#0C0603, 0.20);
	z-index: 99;
	opacity: 0;
	transition: $backdrop-transition;

	&.sideMenuVisible {
		@apply opacity-100 visible;
		transition: $backdrop-transition;
	}
}

.sideMenuWrapper {
	@apply w-screen h-screen max-w-xs top-0 fixed invisible bg-neutral-0;

	z-index: 100;
	transform: translateX(-100vw);
	transition: $sidemenu-transition;

	&.sideMenuVisible {
		@apply visible translate-x-0;
		transition: $sidemenu-transition;
	}

	.sideMenuHeader {
		.closeMenuButton {
			@apply cursor-pointer;
		}
	}

	.sideMenuContent {
		@apply p-6 grid gap-2;
	}
}