.container {
	@apply w-full h-fit border border-solid px-2 py-3 rounded justify-between flex;
	border-color: #cecbca;

	&.error {
		border-color: #f65050;
		background-color: #fbe9e9;

		label {
			color: #751515;
		}

		textarea {
			color: #751515;
			background: none;
			@apply flex flex-1 h-fit;
		}

		&:focus-within {
			border-color: #f65050;
			box-shadow: 0px 0px 0px 4px #fbe9e933;
		}
	}

	&:focus-within {
		border-color: #773faf;
		box-shadow: 0px 0px 0px 4px #dec8f333;
		@apply transition;
	}

	label {
		@apply w-full flex flex-col gap-1;
		letter-spacing: 0.04em;
	}

	textarea {
		@apply w-full h-fit text-neutral-900;

		&::-webkit-outer-spin-button,
		&::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type="number"] {
			-moz-appearance: textfield;
		}

		&:focus-visible {
			outline: none !important;
		}
	}
}
.inputWrapper {
	display: flex;
	width: 100%;
	flex-direction: column;

}

.count {
	display: flex;
	justify-content: flex-end;
	font-size: 12px;
}