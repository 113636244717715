@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	font-family: "Inter", sans-serif;
	background-color: #faf3f0;
}

.display_lg {
	font-family: "DM Serif Display", serif;
	font-size: 3.25rem; //52px
	line-height: 3.625rem; //58px
	font-weight: 400;
	letter-spacing: 0;
}

.display_md {
	font-family: "DM Serif Display", serif;
	font-size: 2.25rem; //36px
	line-height: 2.625rem; //42px
	font-weight: 400;
	letter-spacing: 0;
}

.display_sm {
	font-family: "DM Serif Display", serif;
	font-size: 1.75rem; //28px
	line-height: 2.125rem; //34px
	font-weight: 400;
	letter-spacing: 0;
}

.heading_lg {
	font-family: "Inter", sans-serif;
	font-size: 1.375rem; //22px
	line-height: 1.875rem; //30px
	font-weight: 400;
	letter-spacing: 0;
}

.heading_md {
	font-family: "Inter", sans-serif;
	font-size: 1.25rem; //20px
	line-height: 1.625rem; //26px
	font-weight: 500;
	letter-spacing: 0;
}

.heading_sm {
	font-family: "Inter", sans-serif;
	font-size: 1.125rem; //18px
	line-height: 1.5rem; //24px
	font-weight: 400;
	letter-spacing: 0;
}

.body {
	font-family: "Inter", sans-serif;
	font-size: 1rem; //16px
	line-height: 1.25rem; //20px
	font-weight: 400;
	letter-spacing: 0;
}

.button {
	font-family: "Inter", sans-serif;
	font-size: 1rem; //16px
	line-height: 1.125rem; //18px
	font-weight: 500;
	letter-spacing: 0;
}

.overline_md {
	font-family: "Inter", sans-serif;
	font-size: 0.875rem; //14px
	line-height: 1rem; //16px
	font-weight: 700;
	letter-spacing: 0.04em;
}

.overline_sm {
	font-size: 0.75rem; //12px
	line-height: 0.875rem; //14px
}

@media only screen and (max-width: 768px) {
	.display_lg {
		font-size: 3rem; //48px
		line-height: 3.375rem; //54px
	}

	.display_md {
		font-size: 2rem; //32px
		line-height: 2.375rem; //38px
	}

	.display_sm {
		font-size: 1.5rem; //24px
		line-height: 1.875rem; //30px
	}

	.heading_lg {
		font-size: 1.25rem; //20px
		line-height: 1.75rem; //28px
	}

	.heading_md {
		font-size: 1.125rem; //18px
		line-height: 1.5rem; //24px
	}

	.heading_sm {
		font-size: 1rem; //16px
		line-height: 1.375rem; //22px
	}

	.body {
		font-size: 0.875rem; //14px
		line-height: 1.125rem; //18px
	}

	.button {
		font-size: 0.875rem; //14px
		line-height: 1rem; //16px
	}

	.overline_md {
		font-size: 0.75rem; //12px
		line-height: 0.875rem; //14px
	}

	.overline_sm {
		font-size: 0.625rem; //10px
		line-height: 0.75rem; //12px
	}
}
