.emptyDashboardWrapper {
  @apply h-full flex flex-col justify-center items-center text-center gap-6 p-6;

  .emptyDashboardMessage {
    @apply grid gap-1;

    .messageTitle {
      @apply font-normal text-xl;
      color: #3A3736;
    }

    .messageText {
      @apply font-normal text-base;
      color: #65605D;
    }
  }
}


