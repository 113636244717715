

.container {
	@apply flex gap-2 items-center;
	padding-right: 16px;
	padding-left: 16px;
}

.userContainer {
	@apply flex gap-2 items-center;
	padding-right: 16px;
	padding-left: 16px;
	justify-content: flex-end;
}

.userName {
	@apply flex gap-2 items-center;

}

.messageContainer {
	@apply w-full  flex p-4 flex-col items-start gap-2 self-stretch rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-left: 32px;
	margin-right: 32px;
	margin-top: 8px;
	margin-bottom: 24px;
	padding: 16px;
	width: calc(100% - 128px);
}

.userMessageContainer {
	@apply flex p-4 flex-col items-start gap-2 rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-left: auto;
	margin-right: 32px;
	margin-top: 8px;
	margin-bottom: 24px;
	padding: 16px;
	max-width: calc(100% - 128px);
	min-width: min-content;
}

.userMessageContainerReply {
	@apply w-full flex p-4 flex-col items-start gap-2 self-stretch rounded-lg bg-neutral-0;
	box-shadow: 0px 0px 10px 0px rgba(102, 37, 0, 0.1);
	margin-top: 8px;
	margin-bottom: 24px;
	margin-right: 32px;
	margin-left:32px;
	padding: 16px;
	width: calc(100% - 64px);
}

.avatar {
	@apply w-12 h-12 rounded-full overflow-hidden;

	img {
		@apply w-full h-full object-cover;
	}
}

.messageContent {
	white-space: pre-line;
}

.cardContainer {
	@apply w-[calc(100%-2rem)] border border-solid p-4 rounded-lg;
	box-shadow: 0px 0px 10px 0px #6625001A;

	.cardContent {
		@apply inline-flex justify-between w-full;

		.content {
			@apply inline-flex justify-start gap-2;

			.label {
				@apply grid gap-2;

				.cardTitle {
					@apply font-normal text-base;
					color: #3A3736;
				}

				.cardMessage {
					@apply font-normal text-sm;
					color: #65605D;
				}

				.cardStatus {
					@apply font-semibold text-xs uppercase;
				}
			}
		}

		.goTo {
			@apply self-center;
		}
	}

	&.primary {
		border-color: #773FAF;
		background-color: #F2ECF8;

		.cardStatus {
			color: #773FAF;
		}
	}

	&.neutral {
		border-color: #FFFFFF;
		background-color: #FFFFFF;

		.cardStatus {
			color: #65605D;
		}
	}

	&.clickable {
		cursor: pointer;
	}
}
